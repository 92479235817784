import {Component, componentsMetaData, ComponentType, ecomAppDefID, IComponent} from '../constants';

export class EcomPlatformService {
  constructor(private readonly sdk: IEditorSdk, private readonly appToken: string) {
    //
  }

  private addPage(component: IComponent) {
    return this.sdk.tpa.add.component(this.appToken, {
      appDefinitionId: ecomAppDefID,
      componentType: this.sdk.document.tpa.TPAComponentType.Page,
      page: {
        pageId: component.id,
        shouldNavigate: false,
      },
    });
  }

  private async addWidget(component: IComponent) {
    const {compId} = await this.sdk.tpa.add.component(this.appToken, {
      appDefinitionId: ecomAppDefID,
      componentType: this.sdk.document.tpa.TPAComponentType.Widget,
      widget: {
        widgetId: component.id,
        allPages: component.allPages,
      },
    });
    const componentRef = await this.sdk.document.components.getById(this.appToken, {id: compId});
    if (component.defaultPosition) {
      await this.sdk.components.layout.update(this.appToken, {
        componentRef,
        layout: {defaultPosition: component.defaultPosition},
      });
    }
  }

  public async install(components: Component[]): Promise<any> {
    return Promise.all(
      components.map(async (componentName) => {
        const component = componentsMetaData[componentName];
        if (!component) {
          console.error(`Component ${componentName} does not exist`);
          return Promise.resolve();
        }
        if (component.type === ComponentType.PAGE) {
          return this.addPage(component);
        } else if (component.type === ComponentType.WIDGET) {
          return this.addWidget(component);
        }
      })
    );
  }
}
