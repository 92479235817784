import {Lightbox} from './types';
import {PageMap, StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';

export const membersAppDefId = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const ecomAppDefID = '1380b703-ce81-ff05-f115-39571d94dfcd';
export const addressesAppDefId = '1505b775-e885-eb1b-b665-1e485d9bf90e';
export const myAddressesPageId = 'my_addresses';
export const orderHistoryPageId = 'order_history';
export const wishlistPageId = 'wishlist';
export const myWalletAppDefId = '4aebd0cb-fbdb-4da7-b5d1-d05660a30172';
export const myWalletPageId = 'my_wallet';
export const wishlistAppDefId = '969262e4-c158-4692-8193-a5f335524bff';

export const myWalletInstallDefinition = {
  appDefinitionId: myWalletAppDefId,
  pageId: myWalletPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addApplication',
};

export const myAdressesInstallDefinition = {
  appDefinitionId: addressesAppDefId,
  pageId: myAddressesPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addApplication',
};

export const myOrdersInstallDefinition = {
  appDefinitionId: ecomAppDefID,
  pageId: orderHistoryPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addSection',
};

export const myWishlistInstallDefinition = {
  appDefinitionId: ecomAppDefID,
  pageId: wishlistPageId,
  social: false,
  showInLoginMenu: true,
  method: 'addSection',
};

export const enum Events {
  manageStores = 'manageStores',
  deletePage = 'deletePage',
  addShopPage = 'addShopPage',
}

export const tpaApplicationId = 15;

export const EDITOR_SCRIPT_DSN = 'https://f6d8a78a4e9c42588fef2ae550dab44a@o37417.ingest.sentry.io/5223158';

export const enum SPECS {
  UseLightboxes = 'specs.stores.UseLightboxes',
}

const membersPageStoresOriginKey = 'memberPages.addModal.memberPagesTab.by.stores.label';

export const membersPagesContentKeys = {
  [myOrdersInstallDefinition.pageId]: {
    name: 'memberPages.addModal.memberPagesTab.orders.title',
    shortDescription: 'memberPages.addModal.memberPagesTab.orders.body',
    origin: membersPageStoresOriginKey,
  },
  [myAdressesInstallDefinition.pageId]: {
    name: 'memberPages.addModal.memberPagesTab.addresses.title',
    shortDescription: 'memberPages.addModal.memberPagesTab.addresses.body',
    origin: membersPageStoresOriginKey,
  },
  [myWalletInstallDefinition.pageId]: {
    name: 'memberPages.addModal.memberPagesTab.wallet.title',
    shortDescription: 'memberPages.addModal.memberPagesTab.wallet.body',
    origin: 'memberPages.addModal.memberPagesTab.by.payments.label',
  },
  [myWishlistInstallDefinition.pageId]: {
    name: 'memberPages.addModal.memberPagesTab.wishlist.title',
    shortDescription: 'memberPages.addModal.memberPagesTab.wishlist.body',
    origin: membersPageStoresOriginKey,
  },
};

export const membersPagesScreenshots = {
  [myOrdersInstallDefinition.pageId]: ['my-orders.png', 'my-orders-mobile.png', 'my-orders-mobile-2.png'],
  [myAdressesInstallDefinition.pageId]: [
    'my-addresses.png',
    'my-addresses-2.png',
    'my-addresses-3.png',
    'my-addresses-mobile.png',
    'my-addresses-mobile-2.png',
  ],
  [myWalletInstallDefinition.pageId]: [
    'my-wallet.png',
    'my-wallet-2.png',
    'my-wallet-mobile.png',
    'my-wallet-mobile-2.png',
  ],
  [myWishlistInstallDefinition.pageId]: ['my-wishlist.png'],
};

export const membersPagesFullDescriptionKeys = {
  [myOrdersInstallDefinition.pageId]: {
    title: 'memberPages.addModal.memberPagesTab.orders.description.body',
    bullets: [
      'memberPages.addModal.memberPagesTab.orders.description.bullet1',
      'memberPages.addModal.memberPagesTab.orders.description.bullet2',
    ],
  },
  [myAdressesInstallDefinition.pageId]: {
    title: 'memberPages.addModal.memberPagesTab.addresses.description.body',
    bullets: [
      'memberPages.addModal.memberPagesTab.addresses.description.bullet1',
      'memberPages.addModal.memberPagesTab.addresses.description.bullet2',
    ],
  },
  [myWalletInstallDefinition.pageId]: {
    title: 'memberPages.addModal.memberPagesTab.wallet.description.body',
    bullets: [
      'memberPages.addModal.memberPagesTab.wallet.description.bullet1',
      'memberPages.addModal.memberPagesTab.wallet.description.bullet2',
      'memberPages.addModal.memberPagesTab.wallet.description.bullet3',
    ],
  },
  [myWishlistInstallDefinition.pageId]: {
    title: 'memberPages.addModal.memberPagesTab.wishlist.description.body',
    bullets: [
      'memberPages.addModal.memberPagesTab.wishlist.description.bullet1',
      'memberPages.addModal.memberPagesTab.wishlist.description.bullet2',
    ],
  },
};

export const lightboxes: Lightbox[] = [
  {
    tpaPageId: 'quick_view',
    title: 'Quick View',
    widgetId: StoresWidgetID.PRODUCT_PAGE,
  },
  {
    tpaPageId: 'mini_cart',
    title: 'Mini Cart',
    widgetId: StoresWidgetID.CART,
  },
];

export interface IEcomPublicApi {
  install: (components: Component[]) => Promise<any>;
}

export enum Component {
  CART = 'cart',
  CART_ICON = 'cart-icon',
  CHECKOUT = 'checkout',
  THANK_YOU_PAGE = 'thanks-you-page',
  MY_ORDERS = 'my-orders',
  MY_WISHLIST = 'my-wishlist',
}

export enum ComponentType {
  PAGE,
  WIDGET,
}

export interface IComponent {
  type: ComponentType;
  id: string;
  allPages?: boolean;
  defaultPosition?: {region: string; placement: string};
}

export const componentsMetaData: {[key in Component]: IComponent} = {
  [Component.CART]: {
    type: ComponentType.PAGE,
    id: PageMap.CART,
  },
  [Component.CART_ICON]: {
    type: ComponentType.WIDGET,
    id: StoresWidgetID.CART_ICON,
    allPages: true,
    defaultPosition: {region: 'header', placement: 'RIGHT'},
  },
  [Component.CHECKOUT]: {
    type: ComponentType.PAGE,
    id: PageMap.CHECKOUT,
  },
  [Component.THANK_YOU_PAGE]: {
    type: ComponentType.PAGE,
    id: PageMap.THANKYOU,
  },
  [Component.MY_ORDERS]: {
    type: ComponentType.PAGE,
    id: '',
  },
  [Component.MY_WISHLIST]: {
    type: ComponentType.PAGE,
    id: '',
  },
};
